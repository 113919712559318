import React, { useState } from "react"
import "./WaitListLanding.scss"
import jsonp from "jsonp"
import queryString from "query-string"

export default function WaitListLanding() {
  const [email, setEmail] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const subscribeToNewsLetter = () => {
    const formData = {
      EMAIL: email,
    }
    //https://vegas.us14.list-manage.com/subscribe/post?u=92698ef415f62b0c936ddfa2e&id=415781cad7
    jsonp(
      `https://vegas.us14.list-manage.com/subscribe/post-json?u=92698ef415f62b0c936ddfa2e&amp;id=415781cad7&${queryString.stringify(
        formData
      )}`,
      { param: "c" },
      (err, data) => {
        console.log("err:", err)
        console.log("data:", data)
        if (data.result === "success") {
          setEmail("")
          setShowSuccessMessage(true)
        }
      }
    )
  }
  return (
    <div style={{}} className="wait-list-wrapper">
      <div className="center-col-wrapper">
        <div className="center-header-txt">Hack.Vegas</div>
        <div className="center-desc-txt">
          Work in a cross-functional Product team consisting of a Product
          Manager, a Designer, and Engineers to solve for gentrification in a
          50-hour sprint.
        </div>
        <div className="center-participate-txt">Participate in Summer 2022</div>
        {!!showSuccessMessage ? (
          <>
            <h1 style={{ color: "white" }}>WE'LL KEEP YOU UPDATED!</h1>
          </>
        ) : (
          <>
            <div className="input-wrapper" style={{}}>
              <input
                className="input-field"
                type={"email"}
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={showSuccessMessage ? true : false}
              />
              <div
                className="input-btn-join"
                onClick={() => subscribeToNewsLetter()}
              >
                Join the waitlist
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
