import "./App.css"

import React from "react"
import Header from "./Header/Header"
import SectionOne from "./SectionOne/SectionOne"
import SectionTwo from "./SectionTwo/SectionTwo"
import SectionThree from "./SectionThree/SectionThree"
import SectionFour from "./SectionFour/SectionFour"
import Footer from "./Footer/Footer"
import SectionFive from "./SectionFive/SectionFive"
import WaitListLanding from "./WaitListLanding/WaitListLanding"
export default function App() {
  const status = "dev"
  return (
    <>
      {status === "prod" ? (
        <div>
          <Header />
          <SectionOne />
          <SectionTwo />
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <Footer />
        </div>
      ) : (
        <>
          <WaitListLanding />
        </>
      )}
    </>
  )
}
