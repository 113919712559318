import React from "react"
import Seo from "../components/SEO/seo"
import App from "../components/App"

export default () => (
  <>
    <Seo
      title={"Hack.Vegas"}
      description="Work in a cross-functional Product team consisting of a Product Manager, a Designer, and Engineers to solve for gentrification in a 50-hour sprint."
    />
    <App />
  </>
)
